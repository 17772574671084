import { LogOut } from "lucide-react";

import { NavbarButton } from "./NavbarButton";
import { useLogout } from "../-hooks/useLogout";

export function LogoutButton() {
  const logout = useLogout();

  return (
    <NavbarButton
      tooltipText="Log out"
      icon={LogOut}
      render={({ className, inactiveClassName, children }) => (
        <button
          type="button"
          className={`${className} ${inactiveClassName}`}
          onClick={logout}
        >
          {children}
        </button>
      )}
    />
  );
}
