import {
  Outlet,
  createFileRoute,
  redirect,
  useRouterState,
} from "@tanstack/react-router";
import { Sidebar } from "@dashboard/common/Sidebar/Sidebar";
import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { Select } from "@dashboard/common/ui/select";
import { ConfigurationAccordion } from "./-components/ConfigurationAccordion";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar",
)({
  beforeLoad: ({
    params: { organizationSlug, businessSlug },
    context: { businesses },
  }) => {
    const business = businesses.find(
      (business) => business.slug === businessSlug,
    );

    if (!business) {
      throw redirect({
        to: "/organization/$organizationSlug/settings/$businessSlug/business",
        params: { organizationSlug, businessSlug: businesses[0].slug },
        replace: true,
      });
    }

    return { business };
  },
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.shopify.isPublicApp.ensureData({
      businessSlug,
    });
  },
  component: ConfigurationLayout,
});

function ConfigurationLayout() {
  const { organizationSlug, businessSlug } = Route.useParams();
  const { businesses, trpc } = Route.useRouteContext();
  const routerState = useRouterState();

  const [isPublic] = trpc.dashboard.shopify.isPublicApp.useSuspenseQuery({
    businessSlug,
  });

  let renderShopifySettings = false;

  if (isPublic) {
    renderShopifySettings = true;
  }

  const options = businesses.map((business) => ({
    label: business.name,
    value: business.slug,
  }));

  const groups = [
    {
      title: "Businesses",
      options,
    },
  ];

  const navigate = Route.useNavigate();
  const setBusiness = (value: string) => {
    const endpoint = routerState.location.pathname
      .split(`${businessSlug}/`)
      .pop();

    void navigate({
      to: `/organization/$organizationSlug/settings/$businessSlug/${endpoint}`,
      params: { organizationSlug, businessSlug: value },
    });
  };

  function SidebarHeader() {
    return <h1 className="text-xl font-semibold">Settings</h1>;
  }

  return (
    <div className="flex h-full">
      <Sidebar header={<SidebarHeader />}>
        {businesses.length > 1 && (
          <>
            <Select
              className="mb-4"
              variant="dropdown"
              value={businessSlug}
              onChange={(value) => setBusiness(value)}
              groups={groups}
            />
          </>
        )}

        <SidebarButton
          text="Business Details"
          to="/organization/$organizationSlug/settings/$businessSlug/business"
        />

        <SidebarButton
          text="Team Members"
          to="/organization/$organizationSlug/settings/$businessSlug/team"
        />

        <SidebarButton
          text="Integrations"
          to="/organization/$organizationSlug/settings/$businessSlug/integrations"
        />

        <SidebarButton
          text="External Tickets"
          to="/organization/$organizationSlug/settings/$businessSlug/external-tickets"
        />

        <SidebarButton
          text="Macros"
          to="/organization/$organizationSlug/settings/$businessSlug/macros"
        />

        {renderShopifySettings && (
          <SidebarButton
            text="Shopify Subscription"
            to="/organization/$organizationSlug/settings/$businessSlug/shopify-subscription"
          />
        )}

        <ConfigurationAccordion />
      </Sidebar>
      <div className="h-full flex-grow">
        <Outlet />
      </div>
    </div>
  );
}
